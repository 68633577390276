$cdnFolder: "https://static-checkout.staging-uniplaces.com";
@import "~uniplaces-uniq/src/styles/_main";

@include font-face(
  IntervalNextBook,
  $cdnFolder + "/fonts/intervalnext-book",
  null,
  null,
  woff2
);

@include font-face(
  IntervalNextMed,
  $cdnFolder + "/fonts/intervalnext-medium",
  null,
  null,
  woff2
);

@include font-face(
  IntervalNextReg,
  $cdnFolder + "/fonts/intervalnext-regular",
  null,
  null,
  woff2
);

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

*:focus {
  outline: 0;
}

body {
  font-family: "IntervalNextReg", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  max-width: 100%;
  overflow-x: hidden;
}

button {
  cursor: pointer;
  font-family: IntervalNextReg, sans-serif;
}

a {
  color: inherit;
}

ul,
li {
  list-style: none;
}

h1 {
  font-size: 22px;
  line-height: 26px;
}

textarea {
  font-family: IntervalNextReg, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: IntervalNextMed, sans-serif;
}

// Termly
.t-consentPrompt {
  border-radius: 12px !important;

  span {
    font-family: IntervalNextReg, sans-serif !important;
    font-weight: 400 !important;
    font-size: em(14px) !important;
  }

  @media screen and (max-width: 767px) {
    box-shadow: none !important;
    border-radius: 0 !important;
    width: 100% !important;
    bottom: 0 !important;
    margin: 0 !important;
  }
}

.t-consentPrompt button {
  height: 40px !important;
  border-radius: 8px !important;
  font-family: IntervalNextReg, sans-serif !important;
  font-weight: 400 !important;
  font-size: em(14px) !important;
}

[class^="termly-styles-modal-"] {
  border-radius: 12px !important;
}

.t-preference-modal {
  border-radius: 12px !important;

  >[class^="termly-styles-preferenceContainer-"] {
    border-radius: 0 0 12px 12px !important;
    margin-bottom: 20px;
  }

  >[class^="termly-styles-root-"] {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 20px) !important;
    border-radius: 0 0 12px 12px !important;
    border-top: none !important;

    button {
      border-radius: 8px !important;
      font-family: IntervalNextReg, sans-serif !important;
      font-weight: 400 !important;
      font-size: em(14px) !important;
    }
  }
}
